<template>
  <div class="campaigns-page admin-page">
    <div class="container" :class="{ confirm }">
      <AdminHeader />

      <template
        v-if="!success && !error.active && campaign && coupon && secret"
      >
        <h2 class="heading">Neue Abbuchung</h2>

        <div class="data">
          <div class="pair">
            <p class="name">Kampagne</p>
            <p class="value">{{ campaign.name }}</p>
          </div>
          <div class="pair">
            <p class="name">Gutschein</p>
            <p class="value">{{ coupon.name }}</p>
          </div>
          <div class="pair">
            <p class="name">Art</p>
            <p class="value">
              {{
                coupon.mode == 'value'
                  ? coupon.value.toFixed(2).replace('.', ',') + ' €'
                  : coupon.mode == 'discount'
                  ? coupon.discount * 100 + ' %'
                  : ''
              }}
              {{
                coupon.mode == 'value'
                  ? 'Wertgutschein'
                  : coupon.mode == 'discount'
                  ? 'Rabattgutschein'
                  : 'Produktgutschein'
              }}
            </p>
          </div>
          <div class="pair">
            <p class="name">Ausgestellt an</p>
            <p class="value">{{ coupon.for }}</p>
          </div>
          <div class="pair">
            <p class="name">Gutschein ID</p>
            <p class="value">{{ secret[0].ID }}</p>
          </div>
          <div class="pair">
            <p class="name">Aktueller Wert</p>
            <p class="value">
              {{ currentValue.toFixed(2).replace('.', ',') }} €
            </p>
          </div>
        </div>

        <label for="amount" class="amount">
          <div class="label">
            <p class="text">Betrag</p>
          </div>
          <v-btn
            icon
            :color="negativeAmount ? 'error' : 'success'"
            left
            class="prefix"
            @click="negativeAmount = !negativeAmount"
          >
            <v-icon v-if="negativeAmount">remove</v-icon>
            <v-icon v-else>add</v-icon>
          </v-btn>
          <input
            id="amount"
            type="number"
            width="5"
            v-model="amountAsCurrency"
            @keydown.prevent="handleAmountInput"
          />
          <p class="suffix">€</p>
        </label>
        <div class="amountPair">
          <p class="name">Betrag</p>
          <p class="value" :class="{ positive: !negativeAmount }">
            {{
              (negativeAmount ? '-' : '+') + amountAsCurrency.replace('.', ',')
            }}
            €
          </p>
        </div>

        <div class="valueLeft" v-if="valueAfter < 0">
          <p class="name">Verbleibend</p>
          <p class="value">0,00 €</p>
        </div>

        <div class="valueAfter" :class="{ negative: valueAfter < 0 }">
          <p class="name">
            {{ valueAfter >= 0 ? 'Verbleibend' : 'Zuzahlung' }}
          </p>
          <p class="value">
            {{
              Math.abs(valueAfter)
                .toFixed(2)
                .replace('.', ',')
            }}
            €
          </p>
        </div>

        <v-btn
          elevation="0"
          color="primary"
          class="submit"
          :disabled="amount == 0 || currentValue <= 0"
          v-if="!confirm"
          @click="confirm = true"
          >Abbuchen</v-btn
        >
        <v-btn
          elevation="0"
          color="primary"
          class="submit"
          :disabled="amount == 0"
          :loading="loadingTransaction"
          v-if="confirm"
          @click="confirmDebit"
          >Abbuchung bestätigen</v-btn
        >
      </template>
      <template v-if="success && !error.active">
        <div class="successful">
          <v-icon color="success" class="icon">check_circle</v-icon>
          <h3 class="headline">Abbuchung erfolgreich</h3>
          <p class="description">
            Die Abbuchung vom Gutschein wurde erfolgreich übermittelt. Der/die
            Besitzer*in des Gutscheins sollte die neue Abbuchung in wenigen
            Sekunden angezeigt bekommen (sofern eine außreichende
            Internet-Verbindung vorhanden ist).
          </p>
        </div>
      </template>
    </div>

    <v-dialog v-model="error.active" persistent max-width="500">
      <v-card>
        <template v-if="error.code == 'deactive'">
          <v-card-title style="word-break: break-word;">
            Gutschein deaktiviert
          </v-card-title>
          <v-card-text>
            Der Gutschein, den du versuchst aufzurufen, wurde von einem
            Administrator deaktiviert. Falls es sich dabei um einen Fehler
            handelt, versuche es später erneut oder kontaktiere den Support.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error.code === 'notFound'">
          <v-card-title style="word-break: break-word;">
            Gutschein nicht gefunden
          </v-card-title>
          <v-card-text>
            Wir konnten den Gutschein mit der übergebenen ID Nummer nicht
            finden. Bitte versuche es später erneut oder wende dich an den
            Support.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error.code === 'unknown'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler aufgetreten
          </v-card-title>
          <v-card-text>
            Ein unerwarteter Fehler ist beim Laden des Gutscheins zur Abbuchung
            aufgetreten. Bitte versuche es später erneut oder wende dich an den
            Support.
            {{ error.catched ? '\nFehlercode: ' + error.catched : '' }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, auth, Timestamp } from '@/firebase';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminCampaigns',
  components: { AdminHeader },
  data() {
    return {
      campaign: null,
      coupon: null,
      secret: null,
      secretPath: '',
      error: { active: false, code: '' },
      id: '',
      amount: 0,
      negativeAmount: true,
      confirm: false,
      loadingTransaction: false,
      success: false,
    };
  },
  watch: {
    '$route.params.id': {
      async handler(id) {
        this.id = id;
        try {
          let secret = await this.$bind(
            'secret',
            db
              .collectionGroup('private')
              .where('ID', '==', this.id)
              .limit(1)
          );
          if (!secret.length) {
            this.error = { active: true, code: 'notFound' };
            return;
          }
          let pathParts = await db
            .collectionGroup('private')
            .where('ID', '==', this.id)
            .limit(1)
            .get()
            .then((documents) => {
              return documents.docs[0].ref.path;
            });
          this.secretPath = pathParts;
          pathParts = String(pathParts).split('/');
          if (pathParts.length < 6)
            return (this.error = {
              active: true,
              code: 'unknown',
              catched: 'PathTooShort',
            });
          let path = {
            [pathParts[0]]: pathParts[1],
            [pathParts[2]]: pathParts[3],
            [pathParts[4]]: pathParts[5],
          };
          if (
            !this.$userProfile.admin &&
            !this.$userProfile.canDebit.includes(path.campaigns)
          ) {
            this.$router.replace({
              path: '/d?error=insufficientDebitPermission',
            });
          }
          let coupon = await this.$bind(
            'coupon',
            db.doc(`/campaigns/${path.campaigns}/coupons/${path.coupons}`)
          );

          if (coupon && coupon.deactive === true) {
            this.error = { active: true, code: 'deactive' };
            this.$unbind('secret');
            this.$unbind('coupon');
            return;
          }

          let campaign = await this.$bind(
            'campaign',
            db.doc(`/campaigns/${path.campaigns}`)
          );

          if (!secret || !coupon || !campaign) {
            this.error = { active: true, code: 'notFound' };
          }
        } catch (e) {
          this.error = { active: true, code: 'unknown', catched: e };
          console.log(e);
        }
      },
      immediate: true,
    },
  },
  methods: {
    dismissErrorDialog() {
      this.error = '';
      this.$router.replace({ path: '/d/' });
    },
    handleAmountInput(e) {
      e.preventDefault();
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
        this.amount = parseInt(String(this.amount) + String(e.key));
      }
      if (e.key === 'Backspace') {
        if (this.amount === 0) this.amount = 0;

        let newAmount = String(this.amount);
        console.log(newAmount);
        newAmount = newAmount.substr(0, newAmount.length - 1);
        console.log(newAmount);
        newAmount = parseInt(newAmount);
        console.log(newAmount);

        this.amount = isNaN(newAmount) ? 0 : newAmount;
      }
    },
    async confirmDebit() {
      if (
        !this.secret ||
        !this.secret[0] ||
        !this.secret[0].debits ||
        this.loadingTransaction
      )
        return;

      this.loadingTransaction = true;
      let audio;
      if (window.Audio)
        audio = new Audio(require('@/assets/success-sound.mp3'));

      let path = this.secretPath;
      let debitValue =
        this.valueAfter >= 0
          ? (this.negativeAmount ? -1 : 1) * (Math.abs(this.amount) / 100)
          : (this.negativeAmount ? -1 : 1) * (Math.abs(this.amount) / 100) -
            this.valueAfter;

      try {
        await db.runTransaction((transaction) =>
          transaction.get(db.doc(path)).then((secretDoc) => {
            if (!secretDoc.exists) throw 'Private document does not exists!';

            let oldDebits = Array.isArray(secretDoc.data().debits)
              ? secretDoc.data().debits
              : [];

            let newDebits = [
              ...oldDebits,
              {
                by: {
                  id: auth.currentUser.uid,
                  name: this.$userProfile.name,
                },
                time: Timestamp.fromDate(new Date()),
                value: debitValue,
              },
            ];
            transaction.update(secretDoc.ref, { debits: newDebits });
          })
        );

        if (audio) audio.play();
        setTimeout(() => {
          this.loadingTransaction = false;
          this.success = true;
        });
      } catch (e) {
        alert(
          'Unerwarteter Fehler aufgetreten. Versuche es später erneut oder kontaktiere den Support.\nFehlercode: ' +
            e
        );
      }
    },
  },
  computed: {
    currentValue() {
      if (!this.coupon || this.coupon.mode !== 'value') return 0;

      let value = this.coupon.value;

      if (
        !this.secret[0] ||
        !this.secret[0].debits ||
        !this.secret[0].debits.length
      )
        return parseFloat(value) || 0.0;

      let totalDebit = 0.0;
      this.secret[0].debits.forEach((debit) => {
        totalDebit += debit.value;
      });

      return parseFloat(value) + parseFloat(totalDebit);
    },
    valueAfter() {
      if (!this.coupon || this.coupon.mode !== 'value') return 0;
      let current = this.currentValue;
      let debitValue =
        (this.negativeAmount ? 1 : -1) * (Math.abs(this.amount) / 100);
      return current - debitValue;
    },
    amountAsCurrency: {
      get() {
        return (this.amount / 100).toFixed(2);
      },
      set(value) {
        if (value < 0) {
          value = 0.0;
          this.amount = 1;
        }

        this.amount = value * 100;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.campaigns-page {
  .container {
    .heading {
      font-size: 18px;
      font-weight: 500;
    }

    .data {
      margin-top: 32px;

      .pair {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 16px;

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
          margin-bottom: 4px;
        }

        &:nth-child(5) {
          transition: margin 200ms ease;
        }

        .name {
          color: rgba(#000000, 0.56);
        }

        .value {
          text-align: right;
        }

        p {
          margin: 0;
        }
      }
    }

    .amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 48px auto 0;
      width: 180px;
      height: 64px;
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid rgba(#000000, 0.2);
      position: relative;
      transition: all 200ms ease;

      .label {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        &::before {
          display: block;
          content: '';
          position: absolute;
          left: 50%;
          top: calc(50%);
          height: 2px;
          width: calc(100% + 8px);
          background-color: #ffffff;
          transform: translate(-50%, -50%);
          z-index: -1;
        }

        .text {
          font-size: 12px;
          font-weight: 500;
          color: rgba(#000000, 0.56);
        }
      }

      .prefix {
        margin-left: 8px;
      }

      input {
        width: 100px;
        height: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        caret-color: #428ecc;
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }

      .suffix {
        font-size: 16px;
        font-weight: 500;
        color: rgba(#000000, 0.56);
        text-align: right;
        margin: 0 12px 0 8px;
      }
    }

    &.confirm {
      .data {
        .pair {
          &:nth-child(5) {
            margin-bottom: 32px;
          }
        }
      }
      .amount {
        margin: 0 auto;
        width: 100%;
        border-color: transparent;
        background-color: transparent;
        margin-bottom: -64px;
        opacity: 0;
        pointer-events: none;
      }

      .amountPair {
        opacity: 1;
        pointer-events: initial;
        width: 100%;
        height: auto;
        transform: translateY(0);
        margin: 0 auto 12px auto;
      }

      .valueLeft {
        margin: 0 auto 4px auto;
        width: 100%;
        opacity: 1;
        height: auto;

        .name {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .valueAfter {
        margin: 0 auto;
        width: 100%;

        .name {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .amountPair {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      width: 180px;
      height: 0;
      margin: 0 auto 0 auto;
      opacity: 0;
      pointer-events: none;
      z-index: -1;
      transform: translateY(-43px);
      transition: all 200ms ease;

      .name {
        color: rgba(#000000, 0.56);
      }

      .value {
        flex: none;
        font-size: 14px;
        font-weight: 500;
        text-align: right;
        transition: all 200ms ease;
        margin: 0;
        color: #f44336;

        &.positive {
          color: #4caf50;
        }
      }
    }

    .valueLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      width: 180px;
      transition: all 200ms ease;

      height: 0;
      opacity: 0;

      .name {
        font-size: 12px;
        font-weight: 500;
        color: rgba(#000000, 0.56);
        transition: all 120ms ease;
      }

      .value {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-align: right;
        transition: all 120ms ease;
      }
    }

    .valueAfter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px auto 0 auto;
      width: 180px;
      transition: all 200ms ease;

      .name {
        font-size: 12px;
        font-weight: 500;
        color: rgba(#000000, 0.56);
        transition: all 120ms ease;
      }

      .value {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        text-align: right;
        transition: all 120ms ease;
      }

      &.negative {
        .name {
          color: #ffc107;
        }

        .value {
          color: #ffc107;
        }
      }
    }

    .submit {
      display: block;
      margin: 48px auto 16px auto;
      width: 180px;
    }

    .successful {
      .icon {
        font-size: 64px;
        width: 64px;
        height: 64px;
        display: block;
        margin: 80px auto 0 auto;
      }

      .headline {
        font-size: 20px !important;
        font-weight: 600;
        text-align: center;
        margin: 24px auto 12px auto;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: rgba(#000000, 0.7);
        margin: 0 16px;
      }
    }
  }
}
</style>
